import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import {Modal} from 'antd'

import rootSaga from '../redux/sagas'

import boot from './boot'

import createSagaMiddleware from 'redux-saga'

import createRootReducer from './reducers'

export const history = createBrowserHistory({
	getUserConfirmation: (message, callback) => {

		Modal.confirm({
			title: message,
			okText: 'Stay on this page',
			cancelText: 'Change page',
			onOk: () => callback(false),
			onCancel: () => callback(true)
		})

	}
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


export default function configureStore(preloadedState) {

	const sagaMiddleware = createSagaMiddleware()

	const store = createStore(
		// root reducer with router state
		createRootReducer(history),

		preloadedState,

		composeEnhancers(
			applyMiddleware(
				routerMiddleware(history), // for dispatching history actions
				sagaMiddleware
			)
		)
	)

	sagaMiddleware.run(rootSaga)
	boot(store)

	return store
}

