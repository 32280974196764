import gql from 'graphql-tag'

import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

import {SimpleMedia} from './fragment'

const debug = false

export const searchAward = function(params={}){
	if(debug) console.log('[API]', 'searchAward', params)

	let limit = params.limit || 200
	let skip = params.skip || 0

	return query({
		fetchPolicy: 'network-only',
		variables: {
			query: params.query || '',
			limit,
			skip: skip * limit,
		},
		query: gql`
			query($limit:Int, $skip:Int, $query:String){
			  searchAward(limit:$limit, skip:$skip, query:$query){
			    total
			    limit
			    skip 
			    data{ 
			      _id
			      name
			      logoMedia{
		          ...SimpleMedia
		        }
			    }
			  }
		  }

			${SimpleMedia}
		`
	})
		.then(res => res.data.searchAward)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const getAward = function(_id){
	if(debug) console.log('[API]', 'getAward', {_id})

	return query({
		fetchPolicy: 'network-only',
		variables: {
			_id
		},
		query: gql`
			query($_id:String!){
		    getAwardById(_id:$_id){
		      _id
		      name
		      logo
		      logoMedia{
		        ...SimpleMedia
		      }
		      years{
		        _id
		        year
		        logo
		        logoMedia{
		          ...SimpleMedia
		        }
		      }
		    }
			}
			
			${SimpleMedia}
		`
	})
		.then(res => res.data.getAwardById)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const saveAward = async function(data){
	if(debug) console.log('[API]', 'saveAward', data)

	return (data._id
			? axios.post(`/award/${data._id}`, data)
			: axios.put(`/award`, data)
	)
		.then(res => res.data)

}

export const removeAward = function(_id){
	if(debug) console.log('[API]', 'removeAward', _id)

	return axios.delete(`/award/${_id}`)
		.then(res => res.data)

}
