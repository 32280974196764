import gql from 'graphql-tag'

import {query} from '../helpers/apollo'
import axios from './axios'

const debug = false

export const searchTask = function(params={}){
	if(debug) console.log('[API]', 'searchTask', params)

	const q = gql`
    query($tags:[String] $status:String $skip:Int $limit:Int){
      searchTask(tags:$tags status:$status skip:$skip limit:$limit){
        total
        limit
        skip
        data{
      	  _id
          name
          tags
          status
        }
      }
  	}
	`

	return query({
		fetchPolicy: 'network-only',
		query: q,
		variables: {
			tags: params.tags || [],
			status: params.status || '',
			skip: params.skip || 0,
			limit: params.limit || 10,
		},
	})
		.then(res => res.data.searchTask)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const getTask = function(_id){
	if(debug) console.log('[API]', 'getTask', {_id})

	const q = gql`
	  query($_id:String!){
        getTaskById(_id:$_id){
		        _id
		        name
		        tags
		        status
            paramsAsJson
		        logs{
                _id
				        date
				        text
		        }
		        created
		        updated
        }
    }
	`

	return query({
		fetchPolicy: 'network-only',
		query: q,
		variables: {
			_id
		}
	})
		.then(res => res.data.getTaskById)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const closeTask = function(_id){
	if(debug) console.log('[API]', 'closeTask', _id)

	return axios.get(`/task/${_id}/close`)
		.then(res => res.data)
		.catch(err => {
			throw new Error(err)
		})

}
