import {notification} from 'antd'

export const openNotificationWithIcon = (type, title, content, duration=4.5) => {
	notification[type]({
		message: title,
		description: content,
		duration
	});
};

export const openErrorNotification = content => {
	const nextContent = content.replace('GraphQL error:', '')
	openNotificationWithIcon('error', 'Error', nextContent, 6)
}

export const openSuccessNotification = content => {
	openNotificationWithIcon('success', 'Success', content)
}
