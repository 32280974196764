import axios from './axios'

export const upload = async function (file) {

	const data = new FormData()
	data.append('file', file)

	const res = await axios({
		url: '/media/upload',
		method: 'post',
		headers: {
			'Accept': 'application/json'
		},
		data
	})

	//console.log('🤞 RAW UPLOAD', res)
	return res.data
}
