const actions = {
	LOAD_PAGE: 'LOAD_PAGE',
	LOAD_PAGE_SUCCESS: 'LOAD_PAGE_SUCCESS',
	LOAD_PAGE_ERROR: 'LOAD_PAGE_ERROR',

	CHANGE_PAGE: 'CHANGE_PAGE',
	RESET_PAGE: 'RESET_PAGE',

	SEARCH_PAGE: 'SEARCH_PAGE',
	SEARCH_PAGE_PARAMS: ' SEARCH_PAGE_PARAMS',
	SEARCH_PAGE_SUCCESS: 'SEARCH_PAGE_SUCCESS',
	SEARCH_PAGE_ERROR: 'SEARCH_PAGE_ERROR',

	SAVE_PAGE: 'SAVE_PAGE',
	SAVE_PAGE_SUCCESS: 'SAVE_PAGE_SUCCESS',
	SAVE_PAGE_ERROR: 'SAVE_PAGE_ERROR',

	UPLOAD_PAGE: 'UPLOAD_PAGE',
	UPLOAD_PAGE_SUCCESS: 'UPLOAD_PAGE_SUCCESS',
	UPLOAD_PAGE_ERROR: 'UPLOAD_PAGE_ERROR',

	REMOVE_PAGE: 'REMOVE_PAGE',
	REMOVE_PAGE_SUCCESS: 'REMOVE_PAGE_SUCCESS',
	REMOVE_PAGE_ERROR: 'REMOVE_PAGE_ERROR',

	//--

	loadPage: _id => ({
		type: actions.LOAD_PAGE,
		payload: _id
	}),

	changePage: data => ({
		type: actions.CHANGE_PAGE,
		payload: data
	}),

	resetPage: () => ({
		type: actions.RESET_PAGE
	}),



	searchPage: () => ({
		type: actions.SEARCH_PAGE
	}),

	searchParamsPage: (params={}) => ({
		type: actions.SEARCH_PAGE_PARAMS,
		payload: params
	}),

	savePage: data => ({
		type: actions.SAVE_PAGE,
		payload: data
	}),

	uploadPage: (field, params, cb) => ({
		type: actions.UPLOAD_PAGE,
		payload: {field, ...params, cb}
	}),

	removePage: _id => ({
		type: actions.REMOVE_PAGE,
		payload: _id
	}),

}

export default actions
