import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-distributor'
import * as mediaApi from '../../helpers/api-media'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadDistributor(){
	yield takeEvery(actions.LOAD_DISTRIBUTOR, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadDistributor()', payload)

		try{
			const res = yield api.getDistributor(payload)

			if(res.error){
				yield put({type: actions.LOAD_DISTRIBUTOR_ERROR})
			}else{
				yield put({type: actions.LOAD_DISTRIBUTOR_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_DISTRIBUTOR_ERROR})
		}

	})
}

export function* searchDistributor(){

	yield takeEvery(actions.SEARCH_DISTRIBUTOR, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Distributor.params)
			const res = yield api.searchDistributor(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_DISTRIBUTOR_ERROR})
			}else{
				yield put({type: actions.SEARCH_DISTRIBUTOR_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_DISTRIBUTOR_ERROR})
		}

	})
}

export function* getFullDistributor(){

	yield takeEvery(actions.GETFULL_DISTRIBUTOR, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			const res = yield api.getFullDistributor()

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.GETFULL_DISTRIBUTOR_ERROR})
			}else{
				yield put({type: actions.GETFULL_DISTRIBUTOR_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.GETFULL_DISTRIBUTOR_ERROR})
		}

	})
}

export function* saveDistributor(){
	yield takeEvery(actions.SAVE_DISTRIBUTOR, function* ({payload}){
		if(debug) console.log('[SAGA]', 'saveDistributor()', payload)

		yield put({type: actions.CHANGE_DISTRIBUTOR, payload})
		const distributor = yield select(state => state.Distributor.single)

		try{
			const res = yield api.saveDistributor(distributor)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_DISTRIBUTOR_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Distributor saved')
				yield put({type: actions.SAVE_DISTRIBUTOR_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_DISTRIBUTOR, payload: res._id})

				// Reload the full list
				yield put({type: actions.GETFULL_DISTRIBUTOR})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_DISTRIBUTOR_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadDistributor(){
	yield takeEvery(actions.UPLOAD_DISTRIBUTOR, function* ({payload}){
		const {file, cb} = payload
		if(debug) console.log('[SAGA]', 'uploadDistributor()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			const isNested = payload.field.includes('.')

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.UPLOAD_DISTRIBUTOR_ERROR, payload: res.error})
			}else
			if (isNested) {
				const [parent, index, field] = payload.field.split('.')
				yield put({type: actions.UPLOAD_DISTRIBUTOR_SUCCESS, payload: {parent, index, field, media: res}})
			} else {
				yield put({type: actions.UPLOAD_DISTRIBUTOR_SUCCESS, payload: {field: payload.field, media: res}})
			}

		} catch(err){
			console.log('🔥', err)
			openErrorNotification(err)
			yield put({type: actions.UPLOAD_DISTRIBUTOR_ERROR, payload: err})
		}
	})
}

export function* removeDistributor(){

	yield takeEvery(actions.REMOVE_DISTRIBUTOR, function* ({payload}) {

		try{
			yield api.removeDistributor(payload)
			openSuccessNotification('Distributor removed')

			// Reload the full list
			yield put({type: actions.GETFULL_DISTRIBUTOR})

		} catch(e){
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadDistributor),
		fork(searchDistributor),
		fork(getFullDistributor),
		fork(saveDistributor),
		fork(uploadDistributor),
		fork(removeDistributor),
	])
}