import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-event'
import * as mediaApi from '../../helpers/api-media'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadEvent(){
	yield takeEvery(actions.LOAD_EVENT, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadEvent()', payload)

		try{
			const res = yield api.getEvent(payload)

			if(res.error){
				yield put({type: actions.LOAD_EVENT_ERROR})
			}else{
				yield put({type: actions.LOAD_EVENT_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_EVENT_ERROR})
		}

	})
}

export function* searchEvent(){

	yield takeEvery(actions.SEARCH_EVENT, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Event.params)
			const res = yield api.searchEvent(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_EVENT_ERROR})
			}else{
				yield put({type: actions.SEARCH_EVENT_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_EVENT_ERROR})
		}

	})
}

export function* saveEvent(){
	yield takeEvery(actions.SAVE_EVENT, function* ({payload}){
		if(debug) console.log('[SAGA]', 'saveEvent()', payload)

		yield put({type: actions.CHANGE_EVENT, payload})
		const event = yield select(state => state.Event.single)

		try{
			const res = yield api.saveEvent(event)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_EVENT_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Event saved')
				yield put({type: actions.SAVE_EVENT_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_EVENT, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_EVENT_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadEvent(){
	yield takeEvery(actions.UPLOAD_EVENT, function* ({payload}){
		const {file, cb} = payload
		if(debug) console.log('[SAGA]', 'uploadEvent()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			const isNested = payload.field.includes('.')

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.UPLOAD_EVENT_ERROR, payload: res.error})
			}else
			if (isNested) {
				const [parent, index, field] = payload.field.split('.')
				yield put({type: actions.UPLOAD_EVENT_SUCCESS, payload: {parent, index, field, media: res}})
			} else {
				yield put({type: actions.UPLOAD_EVENT_SUCCESS, payload: {field: payload.field, media: res}})
			}
		} catch(err){
			console.log('🔥', err)
			openErrorNotification(err)
			yield put({type: actions.UPLOAD_EVENT_ERROR, payload: err})
		}
	})
}

export function* removeEvent(){

	yield takeEvery(actions.REMOVE_EVENT, function* ({payload}) {

		try{
			yield api.removeEvent(payload)
			openSuccessNotification('Event removed')
		} catch(e){
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadEvent),
		fork(searchEvent),
		fork(saveEvent),
		fork(uploadEvent),
		fork(removeEvent),
	])
}