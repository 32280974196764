import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {selfRegister, onUsersUpdated} from '../../helpers/socket' //onNewTask
import actions from '../../redux/app/actions'

export default function Socket() {

	const myProfile = useSelector(state => state.MyProfile)
	const dispatch = useDispatch()

	// When the profile become availbale, register myself to the app -----------------------------------------------------
	const register = useCallback(profile => {
		selfRegister({
			_id: profile._id,
			firstName: profile.firstName || '',
			lastName: profile.lastName || '',
			login: profile.login
		})
	}, [])

	useEffect(() => {
		if (myProfile && myProfile._id) register(myProfile)
	}, [myProfile, register])

	// When the list of users are updated from the API, update the list locally ------------------------------------------
	const updateUsers = useCallback(users => {
		dispatch(actions.updateUsers(users))
	}, [dispatch])

	useEffect(() => {
		const offUsersUpdated = onUsersUpdated(updateUsers)
		return () => offUsersUpdated()
	}, [updateUsers])

	// When a new task is created ----------------------------------------------------------------------------------------
	/*const newTask = useCallback(task => {
		console.log('newTask', task)
	}, [])

	useEffect(() => {
		const offNewTask = onNewTask(newTask)
		return () => offNewTask()
	}, [newTask])*/

	return null
}
