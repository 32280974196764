import openSocket from 'socket.io-client'
const socket = openSocket(process.env.REACT_APP_API)
//console.log('Opening socket on ', process.env.REACT_APP_API)


// Pub/Sub
export function onMediaUpdated(cb) {
	const fn = _id => cb(_id)

	socket.on('mediaUpdated', fn)
	return () => socket.removeListener("mediaUpdated", fn)
}

export function onUsersUpdated(cb){
	const fn = _id => cb(_id)

	socket.on('usersUpdated', fn)
	return () => socket.removeListener('usersUpdated', fn)
}

export function onChangeTask(cb) {
	const fn = _id => cb(_id)

	socket.on('changeTask', fn)
	return () => socket.removeListener("changeTask", fn)
}

export function onChangeTasks(cb) {
	const fn = _id => cb(_id)

	socket.on('changeTasks', fn)
	return () => socket.removeListener("changeTasks", fn)
}

export function onNewTask(cb){
	const fn = _id => cb(_id)

	socket.on('newTask', fn)
	return () => socket.removeListener('newTask', fn)
}

export function onUpdateTask(cb){
	const fn = _id => cb(_id)

	socket.on('updateTask', fn)
	return () => socket.removeListener('updateTask', fn)
}

export function onOpenedTasksRefresh(cb){
	const fn = _id => cb(_id)

	socket.on('openedTasksRefresh', fn)
	return () => socket.removeListener('openedTasksRefresh', fn)
}

export function onTaskHasChanged(cb){
	const fn = _id => cb(_id)

	socket.on('taskHasChanged', fn)
	return () => socket.removeListener('taskHasChanged', fn)
}

export function onMailingRefreshed(cb) {
	const fn = _id => cb(_id)

	socket.on('mailingRefreshed', fn)
	return () => socket.removeListener('mailingRefreshed', fn)
}



// Send
export function selfRegister(user) {
	socket.emit('selfRegister', user)
}

export function lock(url) {
	socket.emit('lock', url)
}

export function unlock(url) {
	socket.emit('unlock', url)
}

export function getOpenTasks(){
	socket.emit('getOpenTasks')
}
