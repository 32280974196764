const actions = {
	LOAD_FILM: 'LOAD_FILM',
	LOAD_FILM_SUCCESS: 'LOAD_FILM_SUCCESS',
	LOAD_FILM_ERROR: 'LOAD_FILM_ERROR',

	CHANGE_FILM: 'CHANGE_FILM',
	RESET_FILM: 'RESET_FILM',

	SEARCH_FILM: 'SEARCH_FILM',
	SEARCH_FILM_PARAMS: ' SEARCH_FILM_PARAMS',
	SEARCH_FILM_SUCCESS: 'SEARCH_FILM_SUCCESS',
	SEARCH_FILM_ERROR: 'SEARCH_FILM_ERROR',


	SAVE_FILM: 'SAVE_FILM',
	SAVE_FILM_SUCCESS: 'SAVE_FILM_SUCCESS',
	SAVE_FILM_ERROR: 'SAVE_FILM_ERROR',

	UPLOAD_FILM: 'UPLOAD_FILM',
	UPLOAD_FILM_SUCCESS: 'UPLOAD_FILM_SUCCESS',
	UPLOAD_FILM_ERROR: 'UPLOAD_FILM_ERROR',

	REMOVE_FILM: 'REMOVE_FILM',
	REMOVE_FILM_SUCCESS: 'REMOVE_FILM_SUCCESS',
	REMOVE_FILM_ERROR: 'REMOVE_FILM_ERROR',

	//--

	loadFilm: _id => ({
		type: actions.LOAD_FILM,
		payload: _id
	}),

	changeFilm: data => ({
		type: actions.CHANGE_FILM,
		payload: data
	}),

	resetFilm: () => ({
		type: actions.RESET_FILM
	}),



	searchFilm: () => ({
		type: actions.SEARCH_FILM
	}),

	searchParamsFilm: (params={}) => ({
		type: actions.SEARCH_FILM_PARAMS,
		payload: params
	}),



	saveFilm: data => ({
		type: actions.SAVE_FILM,
		payload: data
	}),

	uploadFilm: (field, params, cb) => ({
		type: actions.UPLOAD_FILM,
		payload: {field, ...params, cb}
	}),

	removeFilm: _id => ({
		type: actions.REMOVE_FILM,
		payload: _id
	}),

}

export default actions