import React from 'react'
import {Link} from 'react-router-dom'

import {LogoWrapper} from './Logo.style'

export default function Logo(props) {

	return (
		<LogoWrapper>
			<Link to="/dashboard">
				{props.collapsed
				  ? <i className="ion ion-md-trophy" />
					: <span>PLAYTIME</span>
				}
			</Link>
		</LogoWrapper>

	)

}
