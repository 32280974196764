export function clearToken() {
	localStorage.removeItem('playtime_token')
}

export function getToken() {
	try {
		return localStorage.getItem('playtime_token') || ''
	} catch (err) {
		clearToken()
	}

	return false
}

export function setToken(token) {
	try {
		return localStorage.setItem('playtime_token', token)
	} catch (err) {
		clearToken()
	}

	return false
}

export function saveProfile(profile){
	localStorage.setItem('playtime_profile', profile)
}

export function clearProfile() {
	localStorage.removeItem('playtime_profile')
}