import React from 'react'
import {Link} from 'react-router-dom'
import {Popover, Button} from 'antd'

import UserAvatar from '../../Components/UserAvatar'

import {TopbarDropdownWrapper} from './Topbar.style'

const noPropagation = e => {
	e.stopPropagation()
	console.log('noPropagation')
}

export default function User(props) {

	const content = (
		<TopbarDropdownWrapper className="isoUserDropdown">
			<Link to="/dashboard/settings" className="isoDropdownLink" onClick={noPropagation}>Settings</Link>
			<Button type="link" className="isoDropdownLink" onClick={props.logout}>Log out</Button>
		</TopbarDropdownWrapper>
	)

	return (
		<Popover placement="bottomRight"
		         content={content} title={null}
		         trigger="hover"
		>
			<span> {/*  ⃪ Used to receive props from Popover and all its need to display someting*/}
				<UserAvatar user={props.user}/>
			</span>
		</Popover>
	)

}
