import gql from 'graphql-tag'

import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

import {SimpleMedia, SimpleFilm} from './fragment'

const debug = false

export const searchMarket = function(params={}){
	if(debug) console.log('[API]', 'searchMarket', params)

	let limit = params.limit || 10
	let skip = params.skip || 0

	return query({
		fetchPolicy: 'network-only',
		variables: {
			query: params.query || '',
			limit,
			skip: limit * skip,
			_film: params._film || '',
		},
		query: gql`
			query($limit:Int, $skip:Int, $query:String, $_film:String){
			  searchMarket(limit:$limit, skip:$skip, query:$query, _film:$_film){
			    total
			    limit
			    skip 
			    data{
			      _id
			      title year
			      releaseDate startDate endDate
			      webVisible
			    }
			  }
		  }
		`
	})
		.then(res => res.data.searchMarket)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const getMarket = function(_id){
	if(debug) console.log('[API]', 'getMarket', _id)

	return query({
		fetchPolicy: 'network-only',
		variables: {
			_id
		},
		query: gql`
			query($_id:String!){
			  getMarketById(_id:$_id){
			    _id
			    title
					summary
					webVisible
					releaseDate
					startDate
					endDate
					email
					contact{
					 _id
						name
						email
						phone
						function
						startDate
						endDate
					}
					contactDescription
					iCalWeb
					iCalMailing
					place
					address
					address2
					postal
					city
					country
					web
					
					# -----
					# MEDIA
					# -----
					cover coverMedia { ...SimpleMedia }
					catalog catalogMedia { ...SimpleMedia }
					schedule scheduleMedia { ...SimpleMedia }
					media {
						_id media mediaMedia{ ...SimpleMedia }
					}
					logo {
						_id logo logoMedia{ ...SimpleMedia }
					}
					
					# --------
					# Publish
					# --------
			    publishWeb
			    twitter{
			      _id tweet image imageMedia{ ...SimpleMedia}
			    }
			    facebook{
			      _id msg link image imageMedia{ ...SimpleMedia}
			    }
			    
			    # ------
			    # Films
			    # ------

			    films{
			      _id
			      webVisible
			      type
			      _film
			      film{ ...SimpleFilm }
			      note
				    group
				    header
				    footer
			      releases{
			        _id date time place note highlight
			      }
			      logos{
			        _id logo logoMedia {...SimpleMedia}
			      }
			      package{
			        _id title description cover coverMedia{ ...SimpleMedia }
			        films{
			          _id
			          _film
			          film{ ...SimpleFilm }
			        }
			      }
			    }
				  
				  mailings{
					  _id
					  title
            template
            totalRecipient
            totalOpened
            sent
				  }
			    
					created
					updated
			  }
			}
			
			${SimpleMedia}
			${SimpleFilm}
		`
	})
		.then(res => res.data.getMarketById)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const saveMarket = function(market){
	//if(debug) console.log('[API]', 'saveMarket', market)

	return (market._id
			? axios.post(`/market/${market._id}`, market)
			: axios.put(`/market`, market)
	)
		.then(res => res.data)

}

export const removeMarket = function(_id){
	if(debug) console.log('[API]', 'removeMarket', _id)

	return axios.delete(`/market/${_id}`)
		.then(res => res.data)

}
