const actions = {
	LOAD_TASK: 'LOAD_TASK',
	LOAD_TASK_SUCCESS: 'LOAD_TASK_SUCCESS',
	LOAD_TASK_ERROR: 'LOAD_TASK_ERROR',

	RESET_TASK: 'RESET_TASK',

	SEARCH_TASK: 'SEARCH_TASK',
	SEARCH_TASK_PARAMS: ' SEARCH_TASK_PARAMS',
	SEARCH_TASK_SUCCESS: 'SEARCH_TASK_SUCCESS',
	SEARCH_TASK_ERROR: 'SEARCH_TASK_ERROR',

	//--

	loadTask: _id => ({
		type: actions.LOAD_TASK,
		payload: _id
	}),
	
	searchTask: () => ({
		type: actions.SEARCH_TASK
	}),

	searchParamsTask: (params={}) => ({
		type: actions.SEARCH_TASK_PARAMS,
		payload: params
	}),

	resetTask: () => ({
		type: actions.RESET_TASK
	}),

}

export default actions