import React from 'react';
import {Switch, Route} from 'react-router-dom'

import LazyComponent from '../../helpers/LazyComponent'

export default function AppRouter({url}) {
	return (
		<Switch>
			<Route exact path={`${url}/`} render={props => LazyComponent(() => import('../Dashboard/Dashboard'), props)}/>
			<Route exact path={`${url}/settings`} render={props => LazyComponent(() => import('../Dashboard/Settings'), props)}/>
			<Route exact path={`${url}/auth`} render={props => LazyComponent(() => import('../Dashboard/Auth'), props)}/>

			<Route exact path={`${url}/user`} render={props => LazyComponent(() => import('../User/UserListing'), props)}/>
			<Route path={`${url}/user/:_id`} render={props => LazyComponent(() => import('../User/UserData'), props)}/>

			<Route exact path={`${url}/task`} render={props => LazyComponent(() => import('../Task/TaskListing'), props)}/>
			<Route path={`${url}/task/:_id`} render={props => LazyComponent(() => import('../Task/TaskView'), props)}/>

			<Route exact path={`${url}/mailing`} render={props => LazyComponent(() => import('../Mailing/MailingListing'), props)}/>
			<Route exact path={`${url}/mailing/:_id/preview`} render={props => LazyComponent(() => import('../Mailing/MailingPreview'), props)}/>
			<Route path={`${url}/mailing/:_id`} render={props => LazyComponent(() => import('../Mailing/MailingData'), props)}/>

			<Route exact path={`${url}/film`} render={props => LazyComponent(() => import('../Film/FilmListing'), props)}/>
			<Route path={`${url}/film/:_id/:pan?`} render={props => LazyComponent(() => import('../Film/FilmData'), props)}/>

			<Route exact path={`${url}/market`} render={props => LazyComponent(() => import('../Market/MarketListing'), props)}/>
			<Route path={`${url}/market/:_id/:pan?`} render={props => LazyComponent(() => import('../Market/MarketData'), props)}/>

			<Route exact path={`${url}/person`} render={props => LazyComponent(() => import('../Person/PersonListing'), props)}/>
			<Route path={`${url}/person/:_id/:pan?`} render={props => LazyComponent(() => import('../Person/PersonData'), props)}/>

			<Route exact path={`${url}/event`} render={props => LazyComponent(() => import('../Event/EventListing'), props)}/>
			<Route path={`${url}/event/:_id`} render={props => LazyComponent(() => import('../Event/EventData'), props)}/>

			<Route exact path={`${url}/section`} render={props => LazyComponent(() => import('../Section/SectionListing'), props)}/>
			<Route path={`${url}/section/:_id`} render={props => LazyComponent(() => import('../Section/SectionData'), props)}/>

			<Route exact path={`${url}/award`} render={props => LazyComponent(() => import('../Award/AwardListing'), props)}/>
			<Route path={`${url}/award/:_id`} render={props => LazyComponent(() => import('../Award/AwardData'), props)}/>

			<Route exact path={`${url}/distributor`} render={props => LazyComponent(() => import('../Distributor/DistributorListing'), props)}/>
			<Route path={`${url}/distributor/:_id`} render={props => LazyComponent(() => import('../Distributor/DistributorData'), props)}/>

			<Route exact path={`${url}/page`} render={props => LazyComponent(() => import('../Page/PageListing'), props)}/>
			<Route path={`${url}/page/:_id/block`} render={props => LazyComponent(() => import('../Page/PageBlock'), props)}/>
			<Route path={`${url}/page/:_id`} render={props => LazyComponent(() => import('../Page/PageData'), props)}/>

		</Switch>
	)
}
