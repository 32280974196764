const actions = {
	LOAD_SECTION: 'LOAD_SECTION',
	LOAD_SECTION_SUCCESS: 'LOAD_SECTION_SUCCESS',
	LOAD_SECTION_ERROR: 'LOAD_SECTION_ERROR',

	CHANGE_SECTION: 'CHANGE_SECTION',
	RESET_SECTION: 'RESET_SECTION',

	SEARCH_SECTION: 'SEARCH_SECTION',
	SEARCH_SECTION_PARAMS: ' SEARCH_SECTION_PARAMS',
	SEARCH_SECTION_SUCCESS: 'SEARCH_SECTION_SUCCESS',
	SEARCH_SECTION_ERROR: 'SEARCH_SECTION_ERROR',


	SAVE_SECTION: 'SAVE_SECTION',
	SAVE_SECTION_SUCCESS: 'SAVE_SECTION_SUCCESS',
	SAVE_SECTION_ERROR: 'SAVE_SECTION_ERROR',

	UPLOAD_SECTION: 'UPLOAD_SECTION',
	UPLOAD_SECTION_SUCCESS: 'UPLOAD_SECTION_SUCCESS',
	UPLOAD_SECTION_ERROR: 'UPLOAD_SECTION_ERROR',

	REMOVE_SECTION: 'REMOVE_SECTION',
	REMOVE_SECTION_SUCCESS: 'REMOVE_SECTION_SUCCESS',
	REMOVE_SECTION_ERROR: 'REMOVE_SECTION_ERROR',

	//--

	loadSection: _id => ({
		type: actions.LOAD_SECTION,
		payload: _id
	}),

	changeSection: data => ({
		type: actions.CHANGE_SECTION,
		payload: data
	}),

	resetSection: () => ({
		type: actions.RESET_SECTION
	}),



	searchSection: () => ({
		type: actions.SEARCH_SECTION
	}),

	searchParamsSection: (params={}) => ({
		type: actions.SEARCH_SECTION_PARAMS,
		payload: params
	}),



	saveSection: data => ({
		type: actions.SAVE_SECTION,
		payload: data
	}),

	uploadSection: (field, params, cb) => ({
		type: actions.UPLOAD_SECTION,
		payload: {field, ...params, cb}
	}),

	removeSection: _id => ({
		type: actions.REMOVE_SECTION,
		payload: _id
	}),

}

export default actions