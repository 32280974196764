import actions from './actions'
import {nextState, upload, uploadPrepare} from '../helper'

let initState = {
	single: {},
	loading: false,
	total: 0,
	params: {
		query: '',
		limit: 50,
		skip: 0,
	},
	data: []
}

export default function eventReducer(state = initState, action) {

	const {type, payload} = action

	switch (type) {

		case actions.LOAD_EVENT_SUCCESS:
			return {
				...state,
				single: payload
			}

		case actions.CHANGE_EVENT:
			return nextState({
				...state,
				single: {
					...state.single,
					...payload
				}
			})

		case actions.RESET_EVENT:
			return {
				...state,
				single: {}
			}



		case actions.SEARCH_EVENT_PARAMS:
			return {
				...state,
				params: {
					...state.params,
					...payload
				}
			}

		case actions.SEARCH_EVENT_SUCCESS:
			return {
				...state,
				total: payload.total,
				data: payload.data
			}



		case actions.UPLOAD_EVENT:
			return nextState({
				...state,
				single: uploadPrepare(state.single, payload)
			})

		case actions.UPLOAD_EVENT_SUCCESS:
			return nextState({
				...state,
				single: upload(state.single, payload)
			})

		case actions.REMOVE_EVENT:
			return {
				...state,
				data: state.data.filter(rec => rec._id !== payload)
			}

		default:
			return state
	}
}
