const actions = {
	LOAD_AWARD: 'LOAD_AWARD',
	LOAD_AWARD_SUCCESS: 'LOAD_AWARD_SUCCESS',
	LOAD_AWARD_ERROR: 'LOAD_AWARD_ERROR',

	CHANGE_AWARD: 'CHANGE_AWARD',
	RESET_AWARD: 'RESET_AWARD',

	SEARCH_AWARD: 'SEARCH_AWARD',
	SEARCH_AWARD_PARAMS: ' SEARCH_AWARD_PARAMS',
	SEARCH_AWARD_SUCCESS: 'SEARCH_AWARD_SUCCESS',
	SEARCH_AWARD_ERROR: 'SEARCH_AWARD_ERROR',


	SAVE_AWARD: 'SAVE_AWARD',
	SAVE_AWARD_SUCCESS: 'SAVE_AWARD_SUCCESS',
	SAVE_AWARD_ERROR: 'SAVE_AWARD_ERROR',

	UPLOAD_AWARD: 'UPLOAD_AWARD',
	UPLOAD_AWARD_SUCCESS: 'UPLOAD_AWARD_SUCCESS',
	UPLOAD_AWARD_ERROR: 'UPLOAD_AWARD_ERROR',

	REMOVE_AWARD: 'REMOVE_AWARD',
	REMOVE_AWARD_SUCCESS: 'REMOVE_AWARD_SUCCESS',
	REMOVE_AWARD_ERROR: 'REMOVE_AWARD_ERROR',

	//--

	loadAward: _id => ({
		type: actions.LOAD_AWARD,
		payload: _id
	}),

	changeAward: data => ({
		type: actions.CHANGE_AWARD,
		payload: data
	}),

	resetAward: () => ({
		type: actions.RESET_AWARD
	}),



	searchAward: () => ({
		type: actions.SEARCH_AWARD
	}),

	searchParamsAward: (params={}) => ({
		type: actions.SEARCH_AWARD_PARAMS,
		payload: params
	}),



	saveAward: data => ({
		type: actions.SAVE_AWARD,
		payload: data
	}),

	uploadAward: (field, params, cb) => ({
		type: actions.UPLOAD_AWARD,
		payload: {field, ...params, cb}
	}),

	removeAward: _id => ({
		type: actions.REMOVE_AWARD,
		payload: _id
	}),

}

export default actions