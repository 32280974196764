import {useSelector} from 'react-redux'

const actions = {
	LOAD_DISTRIBUTOR: 'LOAD_DISTRIBUTOR',
	LOAD_DISTRIBUTOR_SUCCESS: 'LOAD_DISTRIBUTOR_SUCCESS',
	LOAD_DISTRIBUTOR_ERROR: 'LOAD_DISTRIBUTOR_ERROR',

	CHANGE_DISTRIBUTOR: 'CHANGE_DISTRIBUTOR',
	RESET_DISTRIBUTOR: 'RESET_DISTRIBUTOR',

	SEARCH_DISTRIBUTOR: 'SEARCH_DISTRIBUTOR',
	SEARCH_DISTRIBUTOR_PARAMS: ' SEARCH_DISTRIBUTOR_PARAMS',
	SEARCH_DISTRIBUTOR_SUCCESS: 'SEARCH_DISTRIBUTOR_SUCCESS',
	SEARCH_DISTRIBUTOR_ERROR: 'SEARCH_DISTRIBUTOR_ERROR',

	GETFULL_DISTRIBUTOR: ' GETFULL_DISTRIBUTOR',
	GETFULL_DISTRIBUTOR_SUCCESS: 'GETFULL_DISTRIBUTOR_SUCCESS',
	GETFULL_DISTRIBUTOR_ERROR: 'GETFULL_DISTRIBUTOR_ERROR',



	SAVE_DISTRIBUTOR: 'SAVE_DISTRIBUTOR',
	SAVE_DISTRIBUTOR_SUCCESS: 'SAVE_DISTRIBUTOR_SUCCESS',
	SAVE_DISTRIBUTOR_ERROR: 'SAVE_DISTRIBUTOR_ERROR',

	UPLOAD_DISTRIBUTOR: 'UPLOAD_DISTRIBUTOR',
	UPLOAD_DISTRIBUTOR_SUCCESS: 'UPLOAD_DISTRIBUTOR_SUCCESS',
	UPLOAD_DISTRIBUTOR_ERROR: 'UPLOAD_DISTRIBUTOR_ERROR',

	REMOVE_DISTRIBUTOR: 'REMOVE_DISTRIBUTOR',
	REMOVE_DISTRIBUTOR_SUCCESS: 'REMOVE_DISTRIBUTOR_SUCCESS',
	REMOVE_DISTRIBUTOR_ERROR: 'REMOVE_DISTRIBUTOR_ERROR',

	//--

	loadDistributor: _id => ({
		type: actions.LOAD_DISTRIBUTOR,
		payload: _id
	}),

	changeDistributor: data => ({
		type: actions.CHANGE_DISTRIBUTOR,
		payload: data
	}),

	resetDistributor: () => ({
		type: actions.RESET_DISTRIBUTOR
	}),



	searchDistributor: () => ({
		type: actions.SEARCH_DISTRIBUTOR
	}),

	searchParamsDistributor: (params={}) => ({
		type: actions.SEARCH_DISTRIBUTOR_PARAMS,
		payload: params
	}),

	getFullList: () => ({
		type: actions.GETFULL_DISTRIBUTOR
	}),


	saveDistributor: data => ({
		type: actions.SAVE_DISTRIBUTOR,
		payload: data
	}),

	uploadDistributor: (field, params, cb) => ({
		type: actions.UPLOAD_DISTRIBUTOR,
		payload: {field, ...params, cb}
	}),

	removeDistributor: _id => ({
		type: actions.REMOVE_DISTRIBUTOR,
		payload: _id
	}),

}

export default actions


export const GetDistributorFromId = _id => {
	const distributor = useSelector(state => state.Distributor.full || [])
	return distributor.find(d => d._id === _id) || {}
}
