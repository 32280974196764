import gql from 'graphql-tag'

import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

import {SimpleMedia} from './fragment'

const debug = false

export const searchPerson = function(params={}){
	if(debug) console.log('[API]', 'searchPerson', params)

	let limit = params.limit || 10
	let skip = params.skip || 0

	return query({
		fetchPolicy: 'network-only',
		variables: {
			query: params.query || '',
			limit,
			skip: limit * skip
		},
		query: gql`
			query($limit:Int, $skip:Int, $query:String){
			  searchPerson(limit:$limit, skip:$skip, query:$query){
			    total
			    limit
			    skip 
			    data{
			      _id
			      name
            priority
            pictureMedia{
              ...SimpleMedia
            }
		      }
			  }
		  }

      ${SimpleMedia}
    `
	})
		.then(res => res.data.searchPerson)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const getPerson = function(_id){
	if(debug) console.log('[API]', 'getPerson', _id)

	return query({
		fetchPolicy: 'network-only',
		variables: {
			_id
		},
		query: gql`
			query($_id:String!){
			  getPersonById(_id:$_id){
			    _id
			    name
					birthDate
		      birthPlace
		      priority
		      picture
		      pictureMedia{
		        ...SimpleMedia
		      }
		      biography
		      
		      publicMedia{
		        _id
		        media
		        mediaMedia{
	            ...SimpleMedia
		        }
		      }

		      awards{
		        _id _film _event _section _award year
		      }
		
					created
					updated
			  }
			}
			
			${SimpleMedia}
		`
	})
		.then(res => res.data.getPersonById)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const getFilmography = function(_id){
	if(debug) console.log('[API]', 'getFilmography', _id)

	return query({
		fetchPolicy: 'network-only',
		variables: {
			_id
		},
		query: gql`
			query($_id:String!){
			  getFilmography(_id:$_id){
			    films{
            _id
            internationalTitle originalTitle frenchTitle
            domesticReleaseDate
            year
            countries
            genre
			    }
			  }
			}
		`
	})
		.then(res => res.data.getFilmography.films)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const savePerson = function(person){
	//if(debug) console.log('[API]', 'savePerson', person)

	return (person._id
			? axios.post(`/person/${person._id}`, person)
			: axios.put(`/person`, person)
	)
		.then(res => res.data)

}

export const removePerson = function(_id){
	if(debug) console.log('[API]', 'removePerson', _id)

	return axios.delete(`/person/${_id}`)
		.then(res => res.data)

}
