import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-section'
import * as mediaApi from '../../helpers/api-media'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadSection(){
	yield takeEvery(actions.LOAD_SECTION, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadSection()', payload)

		try{
			const res = yield api.getSection(payload)

			if(res.error){
				yield put({type: actions.LOAD_SECTION_ERROR})
			}else{
				yield put({type: actions.LOAD_SECTION_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_SECTION_ERROR})
		}

	})
}

export function* searchSection(){

	yield takeEvery(actions.SEARCH_SECTION, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Section.params)
			const res = yield api.searchSection(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_SECTION_ERROR})
			}else{
				yield put({type: actions.SEARCH_SECTION_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_SECTION_ERROR})
		}

	})
}

export function* saveSection(){
	yield takeEvery(actions.SAVE_SECTION, function* ({payload}){
		if(debug) console.log('[SAGA]', 'saveSection()', payload)

		yield put({type: actions.CHANGE_SECTION, payload})
		const section = yield select(state => state.Section.single)

		try{
			const res = yield api.saveSection(section)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_SECTION_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Section saved')
				yield put({type: actions.SAVE_SECTION_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_SECTION, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_SECTION_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadSection(){
	yield takeEvery(actions.UPLOAD_SECTION, function* ({payload}){
		const {file, cb} = payload
		if(debug) console.log('[SAGA]', 'uploadSection()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			const isNested = payload.field.includes('.')

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.UPLOAD_SECTION_ERROR, payload: res.error})
			}else
			if (isNested) {
				const [parent, index, field] = payload.field.split('.')
				yield put({type: actions.UPLOAD_SECTION_SUCCESS, payload: {parent, index, field, media: res}})
			} else {
				yield put({type: actions.UPLOAD_SECTION_SUCCESS, payload: {field: payload.field, media: res}})
			}
		} catch(err){
			console.log('🔥', err)
			openErrorNotification(err)
			yield put({type: actions.UPLOAD_SECTION_ERROR, payload: err})
		}
	})
}

export function* removeSection(){

	yield takeEvery(actions.REMOVE_SECTION, function* ({payload}) {

		try{
			yield api.removeSection(payload)
			openSuccessNotification('Section removed')
		} catch(e){
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadSection),
		fork(searchSection),
		fork(saveSection),
		fork(uploadSection),
		fork(removeSection),
	])
}