import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-page'
import * as mediaApi from '../../helpers/api-media'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadPage(){
	yield takeEvery(actions.LOAD_PAGE, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadPage()', payload)

		try{
			const res = yield api.getPage(payload)
			if(!res || res.error){
				yield put({type: actions.LOAD_PAGE_ERROR})
			}else{
				yield put({type: actions.LOAD_PAGE_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_PAGE_ERROR})
		}

	})
}

export function* searchPage(){

	yield takeEvery(actions.SEARCH_PAGE, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Page.params)
			const res = yield api.searchPage(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_PAGE_ERROR})
			}else{
				yield put({type: actions.SEARCH_PAGE_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_PAGE_ERROR})
		}

	})
}

export function* savePage(){
	yield takeEvery(actions.SAVE_PAGE, function* ({payload}){
		if(debug) console.log('[SAGA]', 'savePage()', payload)

		yield put({type: actions.CHANGE_PAGE, payload})
		const page = yield select(state => state.Page.single)

		try{
			const res = yield api.savePage(page)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_PAGE_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Page saved')
				yield put({type: actions.SAVE_PAGE_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_PAGE, payload: res._id})

			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_PAGE_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadPage(){
	yield takeEvery(actions.UPLOAD_PAGE, function* ({payload}){
		const {file, cb} = payload
		if(debug) console.log('[SAGA]', 'uploadPage()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			const isNested = payload.field.includes('.')

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.UPLOAD_PAGE_ERROR, payload: res.error})
			}else
			if (isNested) {
				const [parent, index, field] = payload.field.split('.')
				yield put({type: actions.UPLOAD_PAGE_SUCCESS, payload: {parent, index, field, media: res}})
			} else {
				yield put({type: actions.UPLOAD_PAGE_SUCCESS, payload: {field: payload.field, media: res}})
			}

		} catch(err){
			console.log('🔥', err)
			openErrorNotification(err)
			yield put({type: actions.UPLOAD_PAGE_ERROR, payload: err})
		}
	})
}

export function* removePage(){

	yield takeEvery(actions.REMOVE_PAGE, function* ({payload}) {

		try{
			yield api.removePage(payload)
			openSuccessNotification('Page removed')
		} catch(err){
			console.log('🔥', err)
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadPage),
		fork(searchPage),
		fork(savePage),
		fork(uploadPage),
		fork(removePage),
	])
}
