export function nextState(state){
	state.modified = true
	return state
}

export function uploadPrepare(state, payload){
	//console.log('uploadPrepare', payload)

	if (!payload.field.includes('.')) return state

	let [parent, index, field] = payload.field.split('.')

	let nextSrc = state[parent] || []
	index = parseInt(index, 10)

	//console.log({index, nextSrc})

	if(!nextSrc[index]) nextSrc.splice(index, 0, {})

	//console.log({parent, index, field, nextSrc})

	return {
		...state,
		[parent]: nextSrc.map((item, idx) => {
			if (idx !== index) return item

			return {
				...item,
				[field]: null,
				[`${field}Media`]: {}
			}

		})
	}

}

export function upload(state, {parent, index, field, media}){
	//console.log({parent, index, field, media, state})

	if(field && !parent){
		return {
			...state,
			[field]: media._id,
			[`${field}Media`]: media
		}
	}

	let nextSrc = state[parent] || []
	index = parseInt(index, 10)

	//console.log({parent, index, field, media, nextSrc})

	return {
		...state,
		[parent]: nextSrc.map((item, idx) => {
			//console.log('index', index, item, media)
			if (idx !== index) return item

			return {
				...item,
				[field]: media._id,
				[`${field}Media`]: media
			}

		})
	}

}
