import gql from 'graphql-tag'

import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

import {SimpleMedia} from './fragment'

const debug = false

export const searchEvent = function(params={}){
	if(debug) console.log('[API]', 'searchEvent', params)

	let limit = params.limit || 200
	let skip = params.skip || 0

	return query({
		fetchPolicy: 'network-only',
		variables: {
			query: params.query || '',
			limit,
			skip: skip * limit
		},
		query: gql`
			query($limit:Int, $skip:Int, $query:String){
			  searchEvent(limit:$limit, skip:$skip, query:$query){
			    total
			    limit
			    skip 
			    data{ 
			      _id
			      name
			      logoMedia{
			        ...SimpleMedia
			      }
			    }
			  }
		  }
		  
      ${SimpleMedia}
		`
	})
		.then(res => res.data.searchEvent)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const getEvent = function(_id){
	if(debug) console.log('[API]', 'getEvent', {_id})

	return query({
		fetchPolicy: 'network-only',
		variables: {
			_id
		},
		query: gql`
			query($_id:String!){
		    getEventById(_id:$_id){
		      _id
		      name
		      logo
		      logoMedia{
            ...SimpleMedia
		      }
		    }
			}
			
			${SimpleMedia}
		`
	})
		.then(res => res.data.getEventById)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const saveEvent = async function(data){
	//if(debug) console.log('[API]', 'saveEvent', data)

	return (data._id
			? axios.post(`/event/${data._id}`, data)
			: axios.put(`/event`, data)
	)
		.then(res => res.data)

}

export const removeEvent = function(_id){
	if(debug) console.log('[API]', 'removeEvent', _id)

	return axios.delete(`/event/${_id}`)
		.then(res => res.data)

}
