import actions from './actions'

let initState = {
	single: {},
	loading: false,
	total: 0,
	params: {
		query: '',
		status: '',
		limit: 20,
		skip: 0,
	},
	data: []
}

export default function taskReducer(state = initState, action) {

	const {type, payload} = action

	switch (type) {
		case actions.LOAD_TASK_SUCCESS:
			return {
				...state,
				single: payload
			}

		case actions.SEARCH_TASK_PARAMS:
			return {
				...state,
				params: {
					...state.params,
					...payload
				}
			}

		case actions.SEARCH_TASK_SUCCESS:
			return {
				...state,
				total: payload.total,
				data: payload.data
			}

		case actions.RESET_TASK:
			return {
				...state,
				single: {}
			}

		default:
			return state
	}
}
