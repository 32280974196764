import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-award'
import * as mediaApi from '../../helpers/api-media'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadAward(){
	yield takeEvery(actions.LOAD_AWARD, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadAward()', payload)

		try{
			const res = yield api.getAward(payload)

			if(res.error){
				yield put({type: actions.LOAD_AWARD_ERROR})
			}else{
				yield put({type: actions.LOAD_AWARD_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_AWARD_ERROR})
		}

	})
}

export function* searchAward(){

	yield takeEvery(actions.SEARCH_AWARD, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Award.params)
			const res = yield api.searchAward(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_AWARD_ERROR})
			}else{
				yield put({type: actions.SEARCH_AWARD_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_AWARD_ERROR})
		}

	})
}

export function* saveAward(){
	yield takeEvery(actions.SAVE_AWARD, function* ({payload}){
		if(debug) console.log('[SAGA]', 'saveAward()', payload)

		yield put({type: actions.CHANGE_AWARD, payload})
		const award = yield select(state => state.Award.single)

		try{
			const res = yield api.saveAward(award)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_AWARD_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Award saved')
				yield put({type: actions.SAVE_AWARD_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_AWARD, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_AWARD_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadAward(){
	yield takeEvery(actions.UPLOAD_AWARD, function* ({payload}){
		const {file, cb} = payload
		if(debug) console.log('[SAGA]', 'uploadAward()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			const isNested = payload.field.includes('.')

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.UPLOAD_AWARD_ERROR, payload: res.error})
			}else
			if (isNested) {
				const [parent, index, field] = payload.field.split('.')
				yield put({type: actions.UPLOAD_AWARD_SUCCESS, payload: {parent, index, field, media: res}})
			} else {
				yield put({type: actions.UPLOAD_AWARD_SUCCESS, payload: {field: payload.field, media: res}})
			}
		} catch(err){
			console.log('🔥', err)
			openErrorNotification(err)
			yield put({type: actions.UPLOAD_AWARD_ERROR, payload: err})
		}
	})
}

export function* removeAward(){

	yield takeEvery(actions.REMOVE_AWARD, function* ({payload}) {

		try{
			yield api.removeAward(payload)
			openSuccessNotification('Award removed')
		} catch(e){
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadAward),
		fork(searchAward),
		fork(saveAward),
		fork(uploadAward),
		fork(removeAward),
	])
}