import actions from './actions'
import {nextState} from '../helper'

let initState = {
	single: {},
	loading: false,
	total: 0,
	params: {
		query: '',
		limit: 50,
		skip: 0,
	},
	data: []
}

export default function filmReducer(state = initState, action) {

	const {type, payload} = action

	switch (type) {

		case actions.LOAD_MARKET_SUCCESS:
			return {
				...state,
				single: payload
			}

		case actions.CHANGE_MARKET:
			return nextState({
				...state,
				single: {
					...state.single,
					...payload
				}
			})

		case actions.RESET_MARKET:
			return {
				...state,
				single: {}
			}


		case actions.SEARCH_MARKET_PARAMS:
			return {
				...state,
				params: {
					...state.params,
					...payload
				}
			}

		case actions.SEARCH_MARKET_SUCCESS:
			return {
				...state,
				total: payload.total,
				data: payload.data
			}


		case actions.REMOVE_MARKET:
			return {
				...state,
				data: state.data.filter(rec => rec._id !== payload)
			}

		default:
			return state
	}
}
