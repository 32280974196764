const actions = {
	LOAD_EVENT: 'LOAD_EVENT',
	LOAD_EVENT_SUCCESS: 'LOAD_EVENT_SUCCESS',
	LOAD_EVENT_ERROR: 'LOAD_EVENT_ERROR',

	CHANGE_EVENT: 'CHANGE_EVENT',
	RESET_EVENT: 'RESET_EVENT',

	SEARCH_EVENT: 'SEARCH_EVENT',
	SEARCH_EVENT_PARAMS: ' SEARCH_EVENT_PARAMS',
	SEARCH_EVENT_SUCCESS: 'SEARCH_EVENT_SUCCESS',
	SEARCH_EVENT_ERROR: 'SEARCH_EVENT_ERROR',


	SAVE_EVENT: 'SAVE_EVENT',
	SAVE_EVENT_SUCCESS: 'SAVE_EVENT_SUCCESS',
	SAVE_EVENT_ERROR: 'SAVE_EVENT_ERROR',

	UPLOAD_EVENT: 'UPLOAD_EVENT',
	UPLOAD_EVENT_SUCCESS: 'UPLOAD_EVENT_SUCCESS',
	UPLOAD_EVENT_ERROR: 'UPLOAD_EVENT_ERROR',

	REMOVE_EVENT: 'REMOVE_EVENT',
	REMOVE_EVENT_SUCCESS: 'REMOVE_EVENT_SUCCESS',
	REMOVE_EVENT_ERROR: 'REMOVE_EVENT_ERROR',

	//--

	loadEvent: _id => ({
		type: actions.LOAD_EVENT,
		payload: _id
	}),

	changeEvent: data => ({
		type: actions.CHANGE_EVENT,
		payload: data
	}),

	resetEvent: () => ({
		type: actions.RESET_EVENT
	}),



	searchEvent: () => ({
		type: actions.SEARCH_EVENT
	}),

	searchParamsEvent: (params={}) => ({
		type: actions.SEARCH_EVENT_PARAMS,
		payload: params
	}),



	saveEvent: data => ({
		type: actions.SAVE_EVENT,
		payload: data
	}),

	uploadEvent: (field, params, cb) => ({
		type: actions.UPLOAD_EVENT,
		payload: {field, ...params, cb}
	}),

	removeEvent: _id => ({
		type: actions.REMOVE_EVENT,
		payload: _id
	}),

}

export default actions