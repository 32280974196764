const actions = {
	COLLAPSE_CHANGE: 'COLLPSE_CHANGE',
	MODIFY_FLAG: 'MODIFY_FLAG',

	APP_USERS_UPDATE: 'APP_USERS_UPDATE',
	APP_USERS_UPDATE_SUCCESS: 'APP_USERS_UPDATE_SUCCESS',

	toggleCollapsed: () => ({
		type: actions.COLLAPSE_CHANGE
	}),

	modifiyFlag: modified => ({
		type: actions.MODIFY_FLAG,
		payload: modified
	}),

	updateUsers : users => ({
		type: actions.APP_USERS_UPDATE,
		payload: users
	})
}

export default actions