import React from 'react'
import {useSelector, useDispatch} from "react-redux"
import {Layout, Icon, Alert} from 'antd'
import {Prompt} from 'react-router-dom'

import User from './User'
import {HeaderWrapper, Left, Center, Right, Modified} from './Topbar.style'

import appAction from '../../redux/app/actions'
import authAction from '../../redux/auth/actions'


export default function Topbar() {

	const collapsed = useSelector(state => state.App.collapsed || false)
	const modified = useSelector(state => state.App.modified || false)
	const me = useSelector(state => state.MyProfile)

	const dispatch = useDispatch()
	const toggleCollapsed = () => dispatch(appAction.toggleCollapsed())
	const logout = () => dispatch(authAction.logout())


	return (
		<HeaderWrapper collapsed={collapsed}>
			<Layout.Header>

				<Left>
					<Icon
						className="trigger"
						type={collapsed ? 'menu-unfold' : 'menu-fold'}
						onClick={toggleCollapsed}
					/>

					<Modified>
						{modified && <Alert message="Content modified" type="info"/>}
						<Prompt when={Boolean(modified)} message={() => `Save your changes before leaving this page`}/>
					</Modified>
				</Left>

				<Center>
				</Center>

				<Right>
					<User user={me} logout={logout} />
				</Right>


			</Layout.Header>
		</HeaderWrapper>
	)

}
