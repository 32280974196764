import {all} from 'redux-saga/effects'

import App from './app/saga'
import Auth from './auth/saga'
import Task from './task/saga'
import MyProfile from './myprofile/saga'

import User from './user/saga'
import Film from './film/saga'
import Person from './person/saga'
import Market from './market/saga'
import Page from './page/saga'

import Distributor from './distributor/saga'
import Award from './award/saga'
import Event from './event/saga'
import Section from './section/saga'

export default function* rootSaga() {
	yield all([
		App(),
		Auth(),
		Task(),
		MyProfile(),

		User(),
		Film(),
		Person(),
		Market(),
		Page(),

		Distributor(),
		Award(),
		Event(),
		Section(),
	])
}
