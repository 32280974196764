import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-person'
import * as mediaApi from '../../helpers/api-media'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadPerson(){
	yield takeEvery(actions.LOAD_PERSON, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadPerson()', payload)

		try{
			const res = yield api.getPerson(payload)

			if(res.error){
				yield put({type: actions.LOAD_PERSON_ERROR})
			}else{
				yield put({type: actions.LOAD_PERSON_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_PERSON_ERROR})
		}

	})
}

export function* loadPersonFilmography(){
	yield takeEvery(actions.LOAD_PERSON_FILMOGRAPHY, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadPersonFilmography()', payload)

		try{
			const res = yield api.getFilmography(payload)

			if(res.error){
				yield put({type: actions.LOAD_PERSON_FILMOGRAPHY_ERROR})
			}else{
				yield put({type: actions.LOAD_PERSON_FILMOGRAPHY_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_PERSON_FILMOGRAPHY_ERROR})
		}

	})
}

export function* searchPerson(){

	yield takeEvery(actions.SEARCH_PERSON, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Person.params)
			const res = yield api.searchPerson(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_PERSON_ERROR})
			}else{
				yield put({type: actions.SEARCH_PERSON_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_PERSON_ERROR})
		}

	})
}

export function* savePerson(){
	yield takeEvery(actions.SAVE_PERSON, function* ({payload}){
		if(debug) console.log('[SAGA]', 'savePerson()', payload)

		yield put({type: actions.CHANGE_PERSON, payload})
		const person = yield select(state => state.Person.single)

		try{
			const res = yield api.savePerson(person)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_PERSON_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Person saved')
				yield put({type: actions.SAVE_PERSON_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_PERSON, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_PERSON_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadPerson(){
	yield takeEvery(actions.UPLOAD_PERSON, function* ({payload}){
		const {file, cb} = payload
		if(debug) console.log('[SAGA]', 'uploadPerson()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			const isNested = payload.field.includes('.')

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.UPLOAD_PERSON_ERROR, payload: res.error})
			}else
			if (isNested) {
				const [parent, index, field] = payload.field.split('.')
				yield put({type: actions.UPLOAD_PERSON_SUCCESS, payload: {parent, index, field, media: res}})
			} else {
				yield put({type: actions.UPLOAD_PERSON_SUCCESS, payload: {field: payload.field, media: res}})
			}
		} catch(err){
			console.log('🔥', err)
			openErrorNotification(err)
			yield put({type: actions.UPLOAD_PERSON_ERROR, payload: err})
		}
	})
}

export function* removePerson(){

	yield takeEvery(actions.REMOVE_PERSON, function* ({payload}) {

		try{
			yield api.removePerson(payload)
			openSuccessNotification('Person removed')
		} catch(e){
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadPerson),
		fork(loadPersonFilmography),
		fork(searchPerson),
		fork(savePerson),
		fork(uploadPerson),
		fork(removePerson),
	])
}