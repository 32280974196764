export const SimpleMedia = `
	fragment SimpleMedia on Media {
		_id
    url
    name
    properties{
      key
      value
    }
	}
`

export const SimpleFilm = `
	fragment SimpleFilm on Film {
	  _id
    internationalTitle originalTitle frenchTitle
    posterMedia{
	    _id
	    url
	    name
	    properties{
	      key
	      value
	    }
    }

    coverMedia{
	    _id
	    url
	    name
	    properties{
	      key
	      value
	    }
    }
	}
`
