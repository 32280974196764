import {createGlobalStyle} from 'styled-components'
//import {palette} from 'styled-theme'

export default createGlobalStyle`
	
	.ant-row,
	.ant-row-flex{
    & > div {
			padding: 0;
		}
	}
	
	.ant-col{
	  margin-top: 4px;
    margin-bottom: 4px;
	}

	.ant-menu-inline-collapsed-tooltip{
		.ant-tooltip-inner{
			i.ion{
				display: none;
			}
		}
	}
	
	.ant-layout.main{
		transition: all 200ms;
		margin-left: 200px;
		
		&.collapsed{
			margin-left: 79px;
		}
	}

  #nprogress{
	  .spinner{
		  top: 22px !important;
		  right: 5px;
	  }
	  .spinner-icon{
		  height: 14px;
		  width: 14px;
	  }
  }

  main.ant-layout-content{
		padding-top: 65px;
	}
	
	.dash--actions{
		&.space-buttons > *{
			margin: 0 5px 0 0;
			
			&:last-child{
				margin-right: 0;
			}
		}
	}
	
	.sorting-item{
		z-index: 10000; // On top of a lot of things (I Hope)
		background: #FFF;
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);		
	}

	li.sorting-item{
		list-style-type: none;
	}

	td.filmography-actions{
		display: flex;
		justify-content: space-evenly;
		
		i{
			font-size: 22px;
		}
	}

`

/*


	body{
		background: ${props => props.theme.main};
		color: #FFF;
	}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      /*background: ${palette('primary', 0)};
      color: #fff;* /
}
}
.ant-row {
	& > div {
		padding: 0;
	}
}


.isoLeftRightComponent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.isoCenterComponent {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

*/
