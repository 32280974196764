import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-film'
import * as mediaApi from '../../helpers/api-media'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadFilm(){
	yield takeEvery(actions.LOAD_FILM, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadFilm()', payload)

		try{
			const res = yield api.getFilm(payload)

			if(res.error){
				yield put({type: actions.LOAD_FILM_ERROR})
			}else{
				yield put({type: actions.LOAD_FILM_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_FILM_ERROR})
		}

	})
}

export function* searchFilm(){

	yield takeEvery(actions.SEARCH_FILM, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Film.params)
			const res = yield api.searchFilm(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_FILM_ERROR})
			}else{
				yield put({type: actions.SEARCH_FILM_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_FILM_ERROR})
		}

	})
}

export function* saveFilm(){
	yield takeEvery(actions.SAVE_FILM, function* ({payload}){
		if(debug) console.log('[SAGA]', 'saveFilm()', payload)

		yield put({type: actions.CHANGE_FILM, payload})
		const film = yield select(state => state.Film.single)

		try{
			const res = yield api.saveFilm(film)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_FILM_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Film saved')
				yield put({type: actions.SAVE_FILM_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_FILM, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_FILM_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadFilm(){
	yield takeEvery(actions.UPLOAD_FILM, function* ({payload}){
		const {file, cb} = payload
		if(debug) console.log('[SAGA]', 'uploadFilm()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			const isNested = payload.field.includes('.')

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.UPLOAD_FILM_ERROR, payload: res.error})
			}else
			if (isNested) {
				const [parent, index, field] = payload.field.split('.')
				yield put({type: actions.UPLOAD_FILM_SUCCESS, payload: {parent, index, field, media: res}})
			} else {
				yield put({type: actions.UPLOAD_FILM_SUCCESS, payload: {field: payload.field, media: res}})
			}
		} catch(err){
			console.log('🔥', err)
			openErrorNotification(err)
			yield put({type: actions.UPLOAD_FILM_ERROR, payload: err})
		}
	})
}

export function* removeFilm(){

	yield takeEvery(actions.REMOVE_FILM, function* ({payload}) {

		try{
			yield api.removeFilm(payload)
			openSuccessNotification('Film removed')
		} catch(e){
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadFilm),
		fork(searchFilm),
		fork(saveFilm),
		fork(uploadFilm),
		fork(removeFilm),
	])
}