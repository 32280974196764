import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-market'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

const debug = false

export function* loadMarket(){
	yield takeEvery(actions.LOAD_MARKET, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadMarket()', payload)

		try{
			const res = yield api.getMarket(payload)

			if(res.error){
				yield put({type: actions.LOAD_MARKET_ERROR})
			}else{
				yield put({type: actions.LOAD_MARKET_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_MARKET_ERROR})
		}

	})
}

export function* searchMarket(){

	yield takeEvery(actions.SEARCH_MARKET, function* () {
		if(debug) console.log(actions.SEARCH_USER)

		try{
			let params = yield select(state => state.Market.params)
			const res = yield api.searchMarket(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_MARKET_ERROR})
			}else{
				yield put({type: actions.SEARCH_MARKET_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_MARKET_ERROR})
		}

	})
}

export function* saveMarket(){
	yield takeEvery(actions.SAVE_MARKET, function* ({payload}){
		if(debug) console.log('[SAGA]', 'saveMarket()', payload)

		yield put({type: actions.CHANGE_MARKET, payload})
		const market = yield select(state => state.Market.single)

		try{
			const res = yield api.saveMarket(market)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_MARKET_ERROR, payload: res.error})
			}else{
				openSuccessNotification('Market saved')
				yield put({type: actions.SAVE_MARKET_SUCCESS, payload: res})

				// Reload the full data
				yield put({type: actions.LOAD_MARKET, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_MARKET_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* removeMarket(){

	yield takeEvery(actions.REMOVE_MARKET, function* ({payload}) {

		try{
			yield api.removeMarket(payload)
			openSuccessNotification('Market removed')
		} catch(e){
		}
	})

}

//

export default function* rootSaga(){
	yield all([
		fork(loadMarket),
		fork(searchMarket),
		fork(saveMarket),
		fork(removeMarket),
	])
}