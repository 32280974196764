import gql from 'graphql-tag'

import axios from './axios'
import {query} from './apollo'

const debug = false

export const lost = function(login){
	const date = new Date()
	const url = document.location.origin + '/?' + date.getTime()

	return axios
		.post('/lost', {login, url })
		.then(res => res.data)
}

export const reset = function(token, password){
	return axios
		.post(`/reset/${token}`, {password})
		.then(res => res.data)
}

export const login = function(login, password){
	return axios
		.post('/login', {login, password})
		.then(res => res.data)
}

export const auth = function(auth){
	if(debug) console.log('[API]', 'auth', auth)

	return query({
		fetchPolicy: 'network-only',
		variables: {
			auth
		},
		query: gql`
			query ($auth:String!){
			  getUserByAuth(auth:$auth){
			    _id
			    auth
			    login
			    role
			    firstName
			    lastName
			  }		
			}
		`
	})
		.then(res => res.data.getUserByAuth)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const createToken = function(){
	//console.log('[API]', 'createToken')

	return query({
			fetchPolicy: 'network-only',
			query: gql`{
			  createAuthToken{
			    token
			  }		
			}`
		},
	)
		.then(res => res.data.createAuthToken)
		.catch(err => console.log('🔥 GRAPHQL', err))

}
