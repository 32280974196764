import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'
import * as api from '../../helpers/api-task'

import {openErrorNotification} from '../../helpers/notification'

const debug = false

export function* loadTask(){
	yield takeEvery(actions.LOAD_TASK, function* ({payload}){
		if(debug) console.log('[SAGA]', 'loadTask()', payload)

		try{
			const res = yield api.getTask(payload)

			if(res.error){
				yield put({type: actions.LOAD_TASK_ERROR})
			}else{
				yield put({type: actions.LOAD_TASK_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_TASK_ERROR})
		}

	})
}

export function* searchTask(){

	yield takeEvery(actions.SEARCH_TASK, function* () {
		if(debug) console.log(actions.SEARCH_TASK)

		try{
			let params = yield select(state => state.Task.params)
			const res = yield api.searchTask(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_TASK_ERROR})
			}else{
				yield put({type: actions.SEARCH_TASK_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_TASK_ERROR})
		}

	})
}

//

export default function* rootSaga(){
	yield all([
		fork(loadTask),
		fork(searchTask),
	])
}