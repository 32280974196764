const actions = {
	LOAD_PERSON: 'LOAD_PERSON',
	LOAD_PERSON_SUCCESS: 'LOAD_PERSON_SUCCESS',
	LOAD_PERSON_ERROR: 'LOAD_PERSON_ERROR',

	LOAD_PERSON_FILMOGRAPHY: 'LOAD_PERSON_FILMOGRAPHY',
	LOAD_PERSON_FILMOGRAPHY_SUCCESS: 'LOAD_PERSON_FILMOGRAPHY_SUCCESS',
	LOAD_PERSON_FILMOGRAPHY_ERROR: 'LOAD_PERSON_FILMOGRAPHY_ERROR',

	CHANGE_PERSON: 'CHANGE_PERSON',
	RESET_PERSON: 'RESET_PERSON',

	SEARCH_PERSON: 'SEARCH_PERSON',
	SEARCH_PERSON_PARAMS: ' SEARCH_PERSON_PARAMS',
	SEARCH_PERSON_SUCCESS: 'SEARCH_PERSON_SUCCESS',
	SEARCH_PERSON_ERROR: 'SEARCH_PERSON_ERROR',


	SAVE_PERSON: 'SAVE_PERSON',
	SAVE_PERSON_SUCCESS: 'SAVE_PERSON_SUCCESS',
	SAVE_PERSON_ERROR: 'SAVE_PERSON_ERROR',

	UPLOAD_PERSON: 'UPLOAD_PERSON',
	UPLOAD_PERSON_SUCCESS: 'UPLOAD_PERSON_SUCCESS',
	UPLOAD_PERSON_ERROR: 'UPLOAD_PERSON_ERROR',

	REMOVE_PERSON: 'REMOVE_PERSON',
	REMOVE_PERSON_SUCCESS: 'REMOVE_PERSON_SUCCESS',
	REMOVE_PERSON_ERROR: 'REMOVE_PERSON_ERROR',

	//--

	loadPerson: _id => ({
		type: actions.LOAD_PERSON,
		payload: _id
	}),

	loadPersonFilmography: _id => ({
		type: actions.LOAD_PERSON_FILMOGRAPHY,
		payload: _id
	}),

	changePerson: data => ({
		type: actions.CHANGE_PERSON,
		payload: data
	}),

	resetPerson: () => ({
		type: actions.RESET_PERSON
	}),



	searchPerson: () => ({
		type: actions.SEARCH_PERSON
	}),

	searchParamsPerson: (params={}) => ({
		type: actions.SEARCH_PERSON_PARAMS,
		payload: params
	}),



	savePerson: data => ({
		type: actions.SAVE_PERSON,
		payload: data
	}),

	uploadPerson: (field, params, cb) => ({
		type: actions.UPLOAD_PERSON,
		payload: {field, ...params, cb}
	}),

	removePerson: _id => ({
		type: actions.REMOVE_PERSON,
		payload: _id
	}),

}

export default actions