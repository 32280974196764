import React from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Layout, Menu} from 'antd'

import {MenuWrapper} from './Sidebar.style'
import Logo from './Logo'

import appAction from '../../redux/app/actions'

const makeTitle = (icon, name) => {
	return (
		<>
			<i className={`ion ion-md-${icon}`}  />
      <span>{name}</span>
		</>
	)
}

export default function Sidebar() {

	const collapsed = useSelector(state => state.App.collapsed || false)
	const selectedKeys = useSelector(state => state.App.selectedKeys || [])

	const dispatch = useDispatch()
	const toggleCollapsed = () => dispatch(appAction.toggleCollapsed())

	return (
		<Layout.Sider trigger={null} collapsible collapsed={collapsed} onCollapse={toggleCollapsed}
		              style={{
			              overflow: 'auto',
			              height: '100vh',
			              position: 'fixed',
			              left: 0,
		              }}>

			<Logo collapsed={collapsed} />

			<MenuWrapper>
				<Menu theme="dark" selectedKeys={selectedKeys} mode="inline">

					<Menu.Item key="film">
						<Link to="/dashboard/film">{makeTitle('film', 'Film')}</Link>
					</Menu.Item>

					<Menu.Item key="person">
						<Link to="/dashboard/person">{makeTitle('contacts', 'Persons')}</Link>
					</Menu.Item>

					<Menu.Item key="market">
						<Link to="/dashboard/market">{makeTitle('tv', 'Market')}</Link>
					</Menu.Item>

					<Menu.Item key="event">
						<Link to="/dashboard/event">{makeTitle('ribbon', 'Event')}</Link>
					</Menu.Item>

					<Menu.Item key="section">
						<Link to="/dashboard/section">{makeTitle('medal', 'Section')}</Link>
					</Menu.Item>

					<Menu.Item key="award">
						<Link to="/dashboard/award">{makeTitle('trophy', 'Award')}</Link>
					</Menu.Item>

					<Menu.Item key="distributor">
						<Link to="/dashboard/distributor">{makeTitle('megaphone', 'Distributor')}</Link>
					</Menu.Item>

					<Menu.Item key="user">
						<Link to="/dashboard/user">{makeTitle('person', 'Users')}</Link>
					</Menu.Item>

					<Menu.Item key="pages">
						<Link to="/dashboard/page">{makeTitle('document', 'Pages')}</Link>
					</Menu.Item>

					<Menu.Item key="mailing">
						<Link to="/dashboard/mailing">{makeTitle('mail', 'Mailing')}</Link>
					</Menu.Item>

					<Menu.Item key="task">
						<Link to="/dashboard/task">{makeTitle('wifi', 'Tasks')}</Link>
					</Menu.Item>

				</Menu>
			</MenuWrapper>

		</Layout.Sider>

	)

}
