import gql from 'graphql-tag'

import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

import {SimpleMedia} from './fragment'

const debug = false

export const searchPage = function(params={}){
	if(debug) console.log('[API]', 'searchPage', params)

	let limit = params.limit || 200
	let skip = params.skip || 0

	return query({
		fetchPolicy: 'network-only',
		variables: {
			query: params.query || '',
			limit,
			skip: limit * skip
		},
		query: gql`
			query($limit:Int, $skip:Int, $query:String){
			  searchPage(limit:$limit, skip:$skip, query:$query){
			    total
			    limit
			    skip 
			    data{ 
			      _id
			      title 
			    }
			  }
		  }
		`
	})
		.then(res => res.data.searchPage)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const getPage = function(_id){
	if(debug) console.log('[API]', 'getPage', {_id})

	return query({
		fetchPolicy: 'network-only',
		variables: {
			_id
		},
		query: gql`
			query($_id:String!){
		    getPageById(_id:$_id){
		      _id
		      title
          slug
			    excerpt
			    content
			    status

          feature
          featureMedia{
            ...SimpleMedia
          }
			    
			    block{
				    _id _type title subtitle content lines url label mode timer limit position
				    _media media{ ...SimpleMedia }
				    slides{
					    _id _film _market label video
					    film{ _id internationalTitle originalTitle frenchTitle }
              market{ _id title }
				    }
				    quotes{
					    _id _film quote reference
					    film{ _id internationalTitle originalTitle frenchTitle }
				    }
            films{
					    _id _film type
					    film{ _id internationalTitle originalTitle frenchTitle }
				    }
            markets{
					    _id _market
					    market{ _id title }
				    }
            gridItems{
					    _id title text url _media
					    media{ ...SimpleMedia }
				    }
            newsItems{
					    _id title text url label
	            _film film{ _id internationalTitle originalTitle frenchTitle }
	            _media media{ ...SimpleMedia }
				    }

            _film film{ _id internationalTitle originalTitle frenchTitle }

            _market market{ _id title }
            _marketA marketA{ _id title }
				    _marketB marketB{ _id title }

						html
      }

          published
		    }
			}

      ${SimpleMedia}
		`
	})
		.then(res => res.data.getPageById)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const savePage = async function(data){
	if(debug) console.log('[API]', 'savePage', data)

	return (data._id
			? axios.post(`/page/${data._id}`, data)
			: axios.put(`/page`, data)
	)
		.then(res => res.data)

}

export const removePage = function(_id){
	if(debug) console.log('[API]', 'removePage', _id)

	return axios.delete(`/page/${_id}`)
		.then(res => res.data)

}
