import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'

import App from './app/reducer'
import Auth from './auth/reducer'
import Task from './task/reducer'
import MyProfile from './myprofile/reducer'

import User from './user/reducer'
import Film from './film/reducer'
import Person from './person/reducer'
import Market from './market/reducer'
import Page from './page/reducer'

import Distributor from './distributor/reducer'
import Award from './award/reducer'
import Event from './event/reducer'
import Section from './section/reducer'

export default (history) => combineReducers({
	App,
	Auth,
	Task,
	MyProfile,

	User,
	Film,
	Person,
	Market,
	Page,

	Distributor,
	Award,
	Event,
	Section,

	router: connectRouter(history)
})
