import gql from 'graphql-tag'

import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

import {SimpleMedia} from './fragment'

const debug = false

export const searchFilm = function(params={}){
	if(debug) console.log('[API]', 'searchFilm', params)

	let limit = params.limit || 10
	let skip = params.skip || 0

	const variables = {
		query: params.query || '',
		_section: params._section || '',
		limit,
		skip: limit * skip
	}

	console.log(variables)

	return query({
		fetchPolicy: 'network-only',
		variables,

		query: gql`
			query($limit:Int, $skip:Int, $query:String, $_section:String){
			  searchFilm(limit:$limit, skip:$skip, query:$query, _section:$_section){
			    total
			    limit
			    skip 
			    data{
			      _id
			      internationalTitle originalTitle frenchTitle
			      posterMedia{
							...SimpleMedia
						}
			    }
			  }
		  }

			${SimpleMedia}
		`
	})
		.then(res => res.data.searchFilm)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const getFilm = function(_id){
	if(debug) console.log('[API]', 'getFilm', _id)

	return query({
		fetchPolicy: 'network-only',
		variables: {
			_id
		},
		query: gql`
      query($_id:String!){
        getFilmById(_id:$_id){
          _id
          type
          status
          active
          internationalTitle
          originalTitle
          frenchTitle
          catalog
          year
          timing
          timingAlt
          ratio
          master
          timings{
            _id
            timing
            comment
          }
          ratios{
            _id
            ratio
            comment
          }
          availability
          domesticReleaseDate
          domesticDistributor
          distributor
          distributors{
            _id
            _distributor
            visible
          }
          logos{
            _id
            logo
            logoMedia{
              ...SimpleMedia
            }
          }
          seasons{
            _id
            season
            episode
            release
          }
          languages
          eof
          dubbing
          ue
          countries
          abstractEN
          abstractFR
          pitch
          pitchWeb
          genre
          subGenre
          tags
          audience
          priority
					budgetFR
					budgetEN
	        
          festivals{
            _id
            _event
            _section
            year
            doc
            docMedia{
              ...SimpleMedia
            }
            docSection
            docSectionMedia{
              ...SimpleMedia
            }
          }
          awards{
            _id
            _event
            _section
            _award
            year
            doc
            docMedia{
              ...SimpleMedia
            }
          }


          # ------
          # CASTING
          # ------
          imdbID
          director
          creator
          screenWriter
          writingCollaboration
          casting
          producer
          productionCompany
          directorPhotography
          composer
          editor
          costumeDesigner
          sound
          productionDesigner
          camera
          basedOn
          basedOnAuthor


          # ------
          # QUOTE
          # ------
          quotes{
            _id
            quote
            reference
            mailing
          }
          pressReviews{
            _id
            reference
            title
            content
            url
            date
            mailing
            picture
            pictureMedia{
              ...SimpleMedia
            }
          }

          # ------
          # MEDIA
          # ------
          poster
          posterMedia{
            ...SimpleMedia
          }
          posters{
            _id
            poster
            posterMedia{
              ...SimpleMedia
            }
          }
          cover
          coverMedia{
            ...SimpleMedia
          }
          covers{
            _id
            cover
            coverMedia{
              ...SimpleMedia
            }
          }
          pressKit
          pressKitMedia{
            ...SimpleMedia
          }
					pressPhotosUrl
          teaserUrl
          teaserDoc
          teaserDocMedia{
            ...SimpleMedia
          }
          trailerUrl
          trailerDoc
          trailerDocMedia{
            ...SimpleMedia
          }
          reelUrl
          reelDoc
          reelDocMedia{
            ...SimpleMedia
          }

          scriptAvailable
					scriptUrl
					scriptDoc
					scriptDocMedia{
						...SimpleMedia
					}

					created
          updated
        }
      }
			
			${SimpleMedia}
		`
	})
		.then(res => res.data.getFilmById)
		.catch(err => console.log('🔥 GRAPHQL', err))
}

export const saveFilm = function(film){
	//if(debug) console.log('[API]', 'saveFilm', film)

	return (film._id
			? axios.post(`/film/${film._id}`, film)
			: axios.put(`/film`, film)
	)
		.then(res => res.data)

}

export const removeFilm = function(_id){
	if(debug) console.log('[API]', 'removeFilm', _id)

	return axios.delete(`/film/${_id}`)
		.then(res => res.data)

}
