const theme = {
	main: '#f1f3f6',

	fonts: {
		primary: 'Roboto, sans-serif'
	},

	palette: {
		// red gradient
		primary: ['#D32F2F', '#F44336', '#F8877F', '#FFCDD2']
	}
}

export default theme