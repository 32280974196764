import styled from 'styled-components'

export const MenuWrapper = styled.div`

	li{
				
		a{	
			display: flex;
			color: rgba(255, 255, 255, 0.65);
	    align-items: center;
	    height: 40px;
	    overflow: hidden;
	    //background: hotpink;
	    //min-width: 35px;
		}

		i.ion{
			//display: inline-block;
			font-size: 18px;
			color: currentColor;
			width: 20px;
			text-align: center;
		}
		
		span{
			flex: 1;
			color: currentColor;
			margin-left: 5px;
		}
	}
	
	ul.ant-menu-inline-collapsed{
		li{			
			a{
				//width: 20px;
			}

			i.ion{
				align-self: center;
			}

			span{
				//visibility: hidden;
				display: none;
			}
		}

	}
`
