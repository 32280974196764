import styled from 'styled-components'

export const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #000914;
	color: #FFF;
	font-size: 2em;
	margin-bottom: 40px;
	
	a{
		display: block;
		color: currentColor;
		line-height: 64px;
		
		&:hover{
			color: currentColor;
		}
	}
	
	span{
		font-size: 21px;
    font-weight: 100;
    letter-spacing: 0.1em;
	}
`
