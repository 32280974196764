const actions = {
	LOAD_MARKET: 'LOAD_MARKET',
	LOAD_MARKET_SUCCESS: 'LOAD_MARKET_SUCCESS',
	LOAD_MARKET_ERROR: 'LOAD_MARKET_ERROR',

	CHANGE_MARKET: 'CHANGE_MARKET',
	RESET_MARKET: 'RESET_MARKET',

	SEARCH_MARKET: 'SEARCH_MARKET',
	SEARCH_MARKET_PARAMS: ' SEARCH_MARKET_PARAMS',
	SEARCH_MARKET_SUCCESS: 'SEARCH_MARKET_SUCCESS',
	SEARCH_MARKET_ERROR: 'SEARCH_MARKET_ERROR',


	SAVE_MARKET: 'SAVE_MARKET',
	SAVE_MARKET_SUCCESS: 'SAVE_MARKET_SUCCESS',
	SAVE_MARKET_ERROR: 'SAVE_MARKET_ERROR',
	
	REMOVE_MARKET: 'REMOVE_MARKET',
	REMOVE_MARKET_SUCCESS: 'REMOVE_MARKET_SUCCESS',
	REMOVE_MARKET_ERROR: 'REMOVE_MARKET_ERROR',

	//--

	loadMarket: _id => ({
		type: actions.LOAD_MARKET,
		payload: _id
	}),

	changeMarket: data => ({
		type: actions.CHANGE_MARKET,
		payload: data
	}),

	resetMarket: () => ({
		type: actions.RESET_MARKET
	}),



	searchMarket: () => ({
		type: actions.SEARCH_MARKET
	}),

	searchParamsMarket: (params={}) => ({
		type: actions.SEARCH_MARKET_PARAMS,
		payload: params
	}),



	saveMarket: data => ({
		type: actions.SAVE_MARKET,
		payload: data
	}),
	
	removeMarket: _id => ({
		type: actions.REMOVE_MARKET,
		payload: _id
	}),

}

export default actions